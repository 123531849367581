import { useNavigate } from "react-router-dom";
import { Timer } from "../../../../../components";
import OpcoesPagamento from "../../../../../components/OpcoesPagamento";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { useAuth } from "../../../../../hooks/AuthContext";
import { addPagamento } from "../../../../../api/Carrinho/TranferenciaInternacional";
import { Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import useAlert from "../../../../../hooks/useAlert";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import { getlocalStorageTimerCarrinho } from '../../../../../util/LocalStorage'
import { StepEnvio } from "../../../../../util/StepsComercial";

export function Pagamento() {
  // const breadCrumbs = [
  //   {
  //     label: "Remessas Internacionais",
  //     icon: <CompareArrowsIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  //   },
  //   {
  //     label: "Enviar dinheiro para o exterior",
  //     icon: <DoubleArrowIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  //     link: "/carrinho/transferencia-internacional/envio/vitrine",
  //   },
  //   {
  //     label: "Beneficiário",
  //     icon: <FmdGoodIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  //     link: "/carrinho/transferencia-internacional/envio/beneficiario",
  //   },
  //   {
  //     label: "Forma de pagamento",
  //     icon: <PointOfSaleIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
  //   },
  // ];

  const idTipoPedido = 3; //  RemessaEnvio = 18
  const navigation = useNavigate();
  const { user } = useAuth();
  const { showAlertError } = useAlert();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const [dataLocal, setDataLocal] = useState(() => getlocalStorageTimerCarrinho());





  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada)
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    try {
      setLoadingAddPagamento(true);
      const response = await addPagamento(user.id, pagamento);

      if (!!response.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${response.errorMessage} - R18AC`
        );

      if (!!!response)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico:não foi possivel registrar a forma de pagamento selecionada- R0EC8`
        );

      if (response && !response.errorMessage) {
        setTimeout(() => {
          navigation("/carrinho/transferencia-internacional/envio/itemEnvio");
        }, 2000);
      }
      setTimeout(() => {
        setLoadingAddPagamento(false);
      }, 2100);
    } catch (error) {
      setLoadingAddPagamento(false);
      showAlertError(`${error} - RB120`);
    }
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/envio/beneficiario");
  };

  return (
    <div>

      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={true}
        activeStep={2}
        steps={StepEnvio}
      >

        {
          dataLocal &&
          <Timer active={!!dataLocal} dataExpiracao={dataLocal} caminhoRedirect={"/CarrinhoVazio"} />
        }

        {/*<BreadCrumbsPaginas list={breadCrumbs} />*/}
        {loadingAddPagamento ? (
          <Skeleton animation="wave" height={50} width={200} />
        ) : (
          <Typography style={{ fontSize: "17pt", marginBottom: "17pt", marginTop: 25 }}>
            Forma de pagamento
          </Typography>
        )}

        <OpcoesPagamento
          idTipoPedido={idTipoPedido}
          onNext={handlerNext}
          loadingAddPagamento={loadingAddPagamento}
        />
      </CarrinhoPage>
    </div>
  );
}

import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Typography, Hidden, Grid, Box, CssBaseline } from "@mui/material";
import { ButtonAcaoGrande } from "../../components";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import useStyles from "./styles";
import ButtonVoltar from "../../components/Botoes/ButtonVoltar";
import Titulo from "../../components/Textos/Titulo";
import Subtitulo from "../../components/Textos/Subtitulo";
// import Logo from "../../assets/images/logo.png";
import useAlert from "../../hooks/useAlert";
import {
  CampoEmail,
  BoxFinalizacao,
  Rodape, LogoEmpresa,
  EspacoConteudoLateralDireito,
} from "../../components";
import api from "../../api";

export default function RecuperarSenha() {
  const [isFinalizacao, setIsFinalizacao] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const { showAlertError } = useAlert();

  const handlerChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    const response = await api.post(`/Cadastro/SolicitarNovaSenha`, {
      email: email,
    });

    if (
      response &&
      response.data.errorMessage &&
      response.data.errorMessage.length > 0
    ) {
      if (response.data.errorMessage === "Usuário inválido.") {
        setIsFinalizacao(false);
      } else {
        showAlertError(response.data.errorMessage);
        setIsFinalizacao(false);
      }
      setLoading(false);
    } else {
      setIsFinalizacao(true);
    }

    setEmail("");
  };

  const handlerBack = () => {
    navigate("/Login");
  };

  return (
    <>
      <CssBaseline />


      <Grid container component="main" sx={{ height: "100vh" }}>
        <Hidden smDown></Hidden>
        {/* MENU DIREITO */}
        <EspacoConteudoLateralDireito style={{ margin: "auto" }}>
          {isFinalizacao ? (
            <Grid
              item
              xs={6}
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "auto" }}
            >
              <Box>
                <BoxFinalizacao
                  icon={
                    <MarkEmailReadIcon
                      color="primary"
                      sx={{ mb: 3, fontSize: "45px", opacity: 0.5 }}
                    />
                  }
                  titulo="Confirmação de troca de senha"
                  message="Verifique em sua caixa de e-mail o link para atualização da senha!"
                  handleBack={handlerBack}
                />
              </Box>
            </Grid>
          ) : (
            <>
              <Hidden>
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                  }}
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      my: 3,
                      backgroundColor: "none",
                      alignItems: "center",
                    }}
                  >
                    <LogoEmpresa idHotsite={0} />
                  </Box>
                </Grid>
                {/* <Grid item xs={12} alignItems="center" justifyContent="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    component="img"
                    sx={{
                      my: 0,
                      backgroundColor: "none",
                      alignItems: "center",
                    }}
                    alt="Logo"
                    src={Logo}
                  />
                </Grid> */}
              </Hidden>
              <Grid item style={{ margin: "auto" }} xs={12}>
                <Grid item style={{ margin: "auto" }} lg={5}>
                  <Link to="/Login">
                    <ButtonVoltar />
                  </Link>
                  <Box
                    sx={{
                      my: 8,
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Titulo
                      sx={{ mb: 2 }}
                      align="left"
                      label="Esqueceu sua senha?"
                    ></Titulo>
                    <Subtitulo
                      label="Basta inserir o e-mail cadastrado em sua conta e
                    enviaremos um link para que você possa redefinir a sua
                    senha."
                    ></Subtitulo>

                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 1 }}
                    >
                      <CampoEmail
                        value={email}
                        handlerChange={handlerChangeEmail}
                        label="Insira seu e-mail"
                        name="email"
                      />
                      <ButtonAcaoGrande
                        loading={loading}
                        type="submit"
                        texto="Enviar link de redefinição de senha"
                      />

                      <Grid container>
                        <Grid item xs>
                          <Link to="/RecuperarSenha"></Link>
                        </Grid>
                      </Grid>
                      {/* <Typography
                        style={{
                          fontWeight: "light",
                          marginBottom: 20,
                          marginTop: 20,
                          textAlign: "left",
                        }}
                        component="h1"
                        variant="body2"
                      >
                        <Link className={classes.Link} to="">
                          Precisa de ajuda? Entre em contato com a gente
                        </Link>
                      </Typography> */}
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Rodape />
            </>
          )}
        </EspacoConteudoLateralDireito>
      </Grid>
    </>
  );
}

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Badge, Box } from '@mui/material'
import useAlert from "../../hooks/useAlert";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import useStyle from './style'
import usePedido from "../../hooks/usePedido";
import { PositionTooltip, TooltipInformacao } from '../TooltipInformacao'
import { styled } from '@mui/material/styles';


const Cart = () => {

    const { showAlertError, showAlertInfo } = useAlert();
    const { user } = useAuth();
    const navigation = useNavigate();
    const classes = useStyle();
    const { alertInfoPedido, errorsPedido, quantidadePedido, itensPedido } = usePedido();

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            color: "white"
        },
    }));

    const handleChangeURL = (event) => {
        event.preventDefault();
        if (!!itensPedido)
            navigation("/carrinho");
    }

    useEffect(() => {

        if (!!alertInfoPedido) {
            showAlertInfo(alertInfoPedido)
            return;
        }

        if (!!errorsPedido) {
            showAlertError(errorsPedido)
        }

    }, [alertInfoPedido, errorsPedido])

    return <>

        <Box sx={{ mr: 2, cursor: "pointer" }} className={classes.customIconButton} onClick={itensPedido?.itens?.length > 0 ? handleChangeURL : null} >
            <StyledBadge badgeContent={quantidadePedido} color="primary">
                {itensPedido?.itens?.length > 0 ?
                    <TooltipInformacao sx={{ marginRight: -1 }} positionStart={PositionTooltip.bottom} descricao="Confira os itens que você selecionou">
                        <ShoppingCartIcon />
                    </TooltipInformacao>
                    :

                    <TooltipInformacao sx={{ marginRight: -1 }} positionStart={PositionTooltip.bottom} descricao="Você não possui nenhum item no carrinho">
                        <ShoppingCartIcon />
                    </TooltipInformacao>
                }
            </StyledBadge>
        </Box>
    </>;
}

export default Cart;
import { useNavigate, useParams } from "react-router-dom";
import OpcoesPagamento from "../../../../components/OpcoesPagamento";
import CarrinhoPage from "../../../../components/CarrinhoPage";
import { useAuth } from "../../../../hooks/AuthContext";
import { addPagamento } from "../../../../api/Carrinho/Ouro";
import { Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { getlocalStorageTimerCarrinho } from '../../../../util/LocalStorage'
import { Timer } from "../../../../components";
import TiposPedido from '../../../../util/typesTipoPedido'
import useAlert from "../../../../hooks/useAlert";
import { StepComEntrega } from '../../../../util/StepsTurismo'
import usePedido from "../../../../hooks/usePedido";

export function Pagamento() {
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const [dataLocal, setDataLocal] = useState(() => getlocalStorageTimerCarrinho());
  const { showAlertError, showAlertSuccess } = useAlert()
  const [activeStep, setActiveStep] = useState(0);
  const [urlRetorno, setUrlRetorno] = useState(`/carrinho/turismo/entrega/${true}`)
  const { itensPedido } = usePedido();
  const { idTipoPedido } = useParams()

  const [steps, setSteps] = useState([])

  useState(() => {
    let idTipoValorInt = parseInt(idTipoPedido);

    if (idTipoValorInt === 0) {
      showAlertError("Ops, algo deu errado, por favor selecione novamente o endereço - R2DC0")
      setTimeout(() => {
        navigation(urlRetorno);
      }, 2500)
    }

    setActiveStep(2)
    setSteps(StepComEntrega)

  }, [idTipoPedido])


  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    setLoadingAddPagamento(true);

    const response = await addPagamento(user.id, pagamento);

    if (response && !response.errorMessage) {
      setTimeout(() => {
        navigation("/carrinho/Ouro/itens");
      }, 2000);
    }
    setTimeout(() => {
      setLoadingAddPagamento(false);
    }, 2100);
  };

  const handleBack = () => {
    setLoadingAddPagamento(true);

    navigation(urlRetorno);
  };


  const handleBackVoltarComprar = () => {
    setLoadingAddPagamento(true);

    navigation("/carrinho/Compra-ouro/vitrine", {
      replace: false,
    });
  };

  return (
    <div>

      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={true}
        activeStep={activeStep}
        steps={steps}
        disabledVoltarCompras={true}
        handleBackVoltarComprar={handleBackVoltarComprar}
      >

        {
          !!dataLocal &&
          <Timer active={!!dataLocal} dataExpiracao={dataLocal} caminhoRedirect={"/carrinho/transferencia-internacional/envio/vitrine"} />
        }

        {loadingAddPagamento ? (
          <Skeleton animation="wave" height={50} width={200} />
        ) : (
          <Typography style={{ fontSize: "18pt", marginBottom: "18pt" }}>
            Forma de pagamento
          </Typography>
        )}


        {itensPedido === null ? (
          <Skeleton animation="wave" height={50} width={200} />
        ) : (
          <OpcoesPagamento
            idTipoPedido={parseInt(idTipoPedido) ?? TiposPedido.VendaEspecie}
            onNext={handlerNext}
            loadingAddPagamento={loadingAddPagamento}
            idPraca={itensPedido !== null ? itensPedido.praca.id : 0}
          />
        )}

      </CarrinhoPage>
    </div>
  );
}

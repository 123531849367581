
import { toast } from "sonner";

const useAlert = () => {
  return {
    Alert: <></>,
    showAlertSuccess: (content) => {
      if (!!content)
        toast.success(content);

    },
    showAlertError: (content) => {
      if (!!content)
        toast.error(content);

    },
    showAlertInfo: (content) => {
      if (!!content)
        toast.info(content)

    },
    showAlertWarning: (content) => {
      if (!!content)
        toast.warning(content);

    },
  };
};

export default useAlert;
